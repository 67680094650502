import { FC, useContext } from "react";
import { Modal } from "./Modal";
import styled from "styled-components";
import { AppContext } from "../hooks/context";
import { setHelpModal } from "../hooks/state";

export const HelpModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const isShown = state.helpModal != null;
  const shouldClose = () => dispatch(setHelpModal(null));

  return (
    <Modal
      contentWidth="520px"
      isShown={isShown}
      shouldClose={shouldClose}
      title={state.helpModal?.modalTitle}
    >
      <ModalContent>
        <HelpText>{state.helpModal?.modalString}</HelpText>
        <img
          src={`${state.helpModal?.mediaLocation}`}
          alt={state.helpModal?.modalString}
        />
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 1rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const HelpText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  color: #79809d;
  margin: 0 0 1rem;
`;
