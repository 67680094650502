import { FC, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../hooks/context";
import { setHelpModal } from "../hooks/state";

interface ButtonProps {
  helpTitle: string;
  helpString: string;
  mediaString: string;
  buttonText: string;
}

export const HelpButton: FC<ButtonProps> = ({
  helpTitle,
  helpString,
  mediaString,
  buttonText,
}) => {
  const { dispatch } = useContext(AppContext);
  const showHelpModal = () =>
    dispatch(
      setHelpModal({
        modalTitle: helpTitle,
        modalString: helpString,
        mediaLocation: mediaString,
      })
    );

  return (
    <HintButton className="pulse" onClick={showHelpModal}>
      <HintButtonText>{buttonText}</HintButtonText>
    </HintButton>
  );
};

const HintButton = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 226, 255, 1);
  border: none;
  border-radius: 999px;
  z-index: 1;
  transition: 0.1s all linear;

  &::before {
    content: "";
    background-color: rgba(0, 255, 277, 0.3);
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 999px;
    z-index: 1;
    animation: pulse 3s infinite linear;
  }
`;

const HintButtonText = styled.span`
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -1.75rem);
  font-size: 14px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  border-radius: 999px;

  @media screen and (min-width: 600px) {
    ${HintButton}:hover & {
      display: block;
    }
  }
`;
