import React from "react";
import { Buffer } from "buffer";
import ReactDOM from "react-dom/client";
import App from "./apolloSwap/ApolloSwapApp";
import { Web3ContextProvider } from "./components/Web3ContextProvider";
import ReactGA from "react-ga4";

// Node polyfills required by WalletConnect are no longer bundled with webpack
window.Buffer = Buffer;
ReactGA.initialize("G-02BW2DFG61");

const root = ReactDOM.createRoot(document.getElementById("root") as any);
root.render(
  <React.StrictMode>
    <Web3ContextProvider>
      <App />
    </Web3ContextProvider>
  </React.StrictMode>
);
