import { CurrentConfig } from "./config";

// Chains
const MAINNET_CHAIN_ID = 1;
const GOERLI_CHAIN_ID = 5;

export const INPUT_CHAIN_ID = (CurrentConfig.chain = MAINNET_CHAIN_ID);
export const INPUT_CHAIN_URL = CurrentConfig.rpc.mainnet;

export const CHAIN_TO_URL_MAP = {
  [MAINNET_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [GOERLI_CHAIN_ID]: CurrentConfig.rpc.goerli,
};

type ChainInfo = {
  explorer: string;
  label: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrl: string;
};

export const CHAIN_INFO: { [key: string]: ChainInfo } = {
  [MAINNET_CHAIN_ID]: {
    explorer: "https://etherscan.io/",
    label: "Ethereum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrl: CurrentConfig.rpc.mainnet,
  },
  [GOERLI_CHAIN_ID]: {
    explorer: "https://goerli.etherscan.io/",
    label: "Ethereum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrl: CurrentConfig.rpc.goerli,
  },
};

// URLs
export const METAMASK_URL = "https://metamask.io/";

export const MY_TOKEN_LIST = [
  {
    name: "Tether USD",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    symbol: "USDT",
    decimals: 6,
    chainId: 1,
    logoURI:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
  },
  {
    name: "USD Coin",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    symbol: "USDC",
    decimals: 6,
    chainId: 1,
    logoURI:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
  {
    name: "Apollo",
    address: "0xadf86e75d8f0f57e0288d0970e7407eaa49b3cab",
    symbol: "APOLLO",
    decimals: 9,
    chainId: 1,
    logoURI: "https://eastedgestudios.github.io/logos/apollo-token.png",
  },
  {
    name: "Apollo",
    address: "0x8c6D4D4E0CDdb7d223D5BdeE0e0E5A80d22014Eb",
    symbol: "APOLLO",
    decimals: 9,
    chainId: 5,
    logoURI: "https://eastedgestudios.github.io/assets/dapps/apollo-token.png",
  },
];

export const APOLLO = "0xadf86e75d8f0f57e0288d0970e7407eaa49b3cab";

export const DEFAULT_NOTIFICATION_TEXT =
  "Click the flashing blue help indicators for assistance in setting up a swap";

export const SLIPPAGE_TEXT =
  "Before swapping tokens, open the settings menu and change the slippage tolerance to 7%";

export const ZEROS_TEXT =
  "If the swap is failing and your APOLLO amount has a decimal, try rounding the number up or down and set the 1's digit to a '1'";
